<template>
  <div class="page">
    <el-container>
      <el-header>
        <div class="nav">
          <div class="logo">
            <el-image
              style="width: 261px; height: 36px"
              :src="require('@/assets/image/bmark-icon.png')"
              fit="contain"
            ></el-image>
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleLink"
            background-color="#060e2d"
            text-color="#fff"
            active-text-color="#fbbd5e"
          >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-submenu index="1">
              <template slot="title">版权服务</template>
              <el-menu-item
                index="https://china.bmark.cn/copyrightRegistration/evidence"
                >版权存证</el-menu-item
              >
              <el-menu-item
                index="https://china.bmark.cn/copyrightRegistration/copyright"
                >版权登记</el-menu-item
              >
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">数字发行</template>
              <el-menu-item index="2-1">数字出版物发行</el-menu-item>
              <el-menu-item index="2-2">IP合作</el-menu-item>
            </el-submenu>
            <el-menu-item index="3" disabled>内容审核</el-menu-item>
            <el-menu-item index="7" disabled>开放平台</el-menu-item>
            <el-menu-item index="5" disabled>专属客服</el-menu-item>
          </el-menu>
          <el-popover placement="top-start" width="200" trigger="hover">
            <div style="display: flex; align-items: center" slot="reference">
              <i class="el-icon-user"></i>
              <span style="font-size: 14px; margin-left: 4px">{{
                userInfo.phone
              }}</span>
            </div>
            <div style="cursor: pointer;">
              <div style="padding: 10px 0 20px">
                升级为斑马企业用户，可享受完整企业用户特权
              </div>
              <el-button
			  v-if="userType == 0"
              @click="handleLink('/identity')"
                class="hvr-wobble-vertical"
                plain
                style="
                  width: 100%;
                  background-color: #ffb216;
                  border: none;
                  color: #fff;
                "
                >立即入驻<i class="el-icon-right el-icon--right"></i
              ></el-button>
              <el-button
			  v-if="userType == 1"
              @click="handleLink('/agencyRegister/createShop')"
                class="hvr-wobble-vertical"
                plain
                style="
                  width: 100%;
                  background-color: #ffb216;
                  border: none;
                  color: #fff;
                "
                >开通店铺<i class="el-icon-right el-icon--right"></i
              ></el-button>
              <el-button
			  v-if="userType == 2"
              @click="handleLink('/agencyRegister/createShop')"
                class="hvr-wobble-vertical"
                plain
                style="
                  width: 100%;
                  background-color: #ffb216;
                  border: none;
                  color: #fff;
                "
                >结算认证<i class="el-icon-right el-icon--right"></i
              ></el-button>
              <ul style="padding: 20px 0 0">
                <li
                  style="
                    font-size: 14px;
                    padding: 10px;
                    border-bottom: 1px solid #dcdfe6;
                    display: flex;
                    align-items: center;
                  "
                  @click="handleLink('/identityInfo')"
                >
                  <i class="el-icon-box" style="font-size: 20px; margin-right: 10px;"></i>
                  企业店铺
                </li>
                <!-- <li
                  style="
                    font-size: 14px;
                    padding: 10px;
                    border-bottom: 1px solid #dcdfe6;
                    display: flex;
                    align-items: center;
                  "
                >
                  <i class="el-icon-coordinate" style="font-size: 20px; margin-right: 10px;"></i>
                  结算认证
                </li> -->
                <li
                  style="
                    font-size: 14px;
                    padding: 10px;
                    border-bottom: 1px solid #dcdfe6;
                    display: flex;
                    align-items: center;
                  "
                >
                  <i class="el-icon-wallet" style="font-size: 20px; margin-right: 10px;"></i>
                  企业钱包
                </li>
                <li
                  style="
                    font-size: 14px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                  "
                  @click="outLogin"
                >
                  <i class="el-icon-circle-close" style="font-size: 20px; margin-right: 10px;"></i>
                  退出登录
                </li>
              </ul>
            </div>
          </el-popover>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="footer">
          <div class="module" id="contact">
            <div class="float">
              <div class="left">
                <div class="text">
                  <p class="title2">联系我们</p>
                  <p class="textItem">028-8707 2498 / 1235635233(QQ)</p>
                  <p class="title2">服务时间</p>
                  <p class="textItem">
                    周一至周五(除国家法定节假日) 09:00-18:00
                  </p>
                  <p class="title2">网络文化经营许可证</p>
                  <p class="textItem">
                    <el-link
                      href="https://beian.miit.gov.cn/"
                      :underline="false"
                      target="_blank"
                      >蜀ICP备20000718号</el-link
                    >
                  </p>
                </div>
              </div>
              <div class="right">
                <div>
                  <el-image
                    :src="require('@/assets/image/gzhQrCode.png')"
                    style="width: 100px; height: 100px"
                    fit="contain"
                  ></el-image>
                  <div>斑马中国公众号</div>
                </div>
                <div>
                  <el-image
                    :src="require('@/assets/image/AppQrCode.png')"
                    style="width: 100px; height: 100px"
                    fit="contain"
                  ></el-image>
                  <div>斑马中国 APP</div>
                </div>
                <div>
                  <el-image
                    :src="require('@/assets/image/bmarkH5.png')"
                    style="width: 100px; height: 100px"
                    fit="contain"
                  ></el-image>
                  <div>斑马中国 H5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

    <script>
export default {
  data() {
    return {
      activeIndex: "/home",
      userInfo: {},
	  userType: 0,
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.activeIndex = val.path;
      },
      deep: true,
    },
  },
  created() {
    this.getUserInfo();
	this.getIdentifyInfo()
  },
  methods: {
	// 获取店铺信息
	// 银联 0: 审核中 1: 审核成功，2: 审核失败
	// 钱包 1: 进件未成功，2: 未上传商户资料，3: 未开通钱包，4: 未签约，5: 已签约
	getIdentifyInfo() {
		this.$http.getContractResult().then((res) => {
			  if (res.code == 0 && res.data) {
				  if(res.data.identify && !res.data.userShop){
					  this.userType = 1
					  return
				  }
				  if(res.data.identify && res.data.userShop){
					  this.userType = 2
					  return
				  }
			  }
		});
	},
	// 退出登录
	outLogin() {
		window.localStorage.removeItem("token");
		// window.open('https://china.bmark.cn/login/page/index?status=login','_self');
		this.$router.push("/login");
	},
    // 获取用户信息
    getUserInfo() {
      this.$http.getUserInfo().then((res) => {
        this.userInfo = {
          ...res.data.sysUser,
          phone: res.data.sysUser.phone.replace(
            /^(.{3})(?:\d+)(.{4})$/,
            "$1****$2"
          ),
        };
      });
    },
    handleLink(link) {
      console.log(link);
      if (link.indexOf("http") == -1) {
        this.$router.push(link);
      } else {
        window.open(link);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
div.page {
  width: 100vw;
  min-height: 100vh;
  font-family: "HarmonyOsSansR";
  .el-header,
  .el-footer {
    padding: 0 !important;
  }
  .el-main {
    padding: 0 !important;
  }
  .el-menu {
    margin-left: 22vw;
  }
  .el-menu-item {
    font-size: 16px;
  }
  div.nav {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    background-color: #060e2d;
    cursor: pointer;
    div.logo {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 6px;
        font-size: 24px;
        font-weight: bold;
        font-family: "HarmonyOsSansM" !important;
      }
    }
    ul.menu-item {
      z-index: 4;
      font-weight: bold;
      display: flex;
      align-items: center;
      opacity: 1;
      transition: opacity 0.3s linear;
      transform: translate(0px, 0);
      li {
        margin-left: 30px;
        padding: 10px 2px 10px;
        text-align: center;
        font-family: HarmonyOsSansR;
        font-size: 18px;
        font-weight: bold;
      }
      div.el-image {
        img {
          height: auto;
        }
      }
    }
  }
  div.footer {
    width: 100vw;
    background-color: #060e2d;
    div.module {
      padding: 20px 22vw 20px;
      div.float {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div.right {
          width: 50%;
          height: 50%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          & > div {
            text-align: center;
            font-weight: bold;
            margin: 0 10px;
            font-size: 12px;
            color: #909399;
          }
          .el-image {
            width: 80px;
            height: 80px;
            margin-bottom: 6px;
          }
        }
        div.text {
          text-align: left;
          color: #909399;
          font-weight: 500;
          font-style: 16px;
          p.title2 {
            font-size: 12px;
            font-family: "HarmonyOsSansM";
            color: #61688a;
            font-style: italic;
          }
          p.textItem {
            line-height: 20px;
            padding: 2px 0 4px;
            font-size: 12px;
          }
          p.textItem:last-of-type {
            padding: 2px 0 0;
          }
        }
      }
      .el-link {
        font-size: 12px;
        color: #909399;
        cursor: pointer;
      }
    }
  }
}
</style>